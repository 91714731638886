


























































































































































































































import Vue from 'vue';
import * as Api from '@/api/memberLevel';
import moment from 'moment';
import dataModal from './data.vue'
const columns = [
  {
    title: '等级名称',
    dataIndex: 'gradeName',
    key: 'gradeName',
    align: 'center',
  },
  {
    title: '等级规则',
    dataIndex: 'levelRules',
    key: 'levelRules',
    align: 'center',
  },
  {
    title: '等级权益',
    dataIndex: 'gradeEquity',
    key: 'gradeEquity',
    align: 'center'
  },
  {
    title: '企业会员',
    dataIndex: 'corporateMember',
    key: 'corporateMember',
    align: 'center',
    width: '180px',
    scopedSlots: { customRender: 'corporateMember' },
  },
  {
    title: '个人会员',
    dataIndex: 'individualMember',
    key: 'individualMember',
    align: 'center',
    scopedSlots: { customRender: 'individualMember' },
  },
  {
    title: '创建人',
    dataIndex: 'extend1',
    key: 'extend1',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'recDateStr',
    key: 'recDateStr',
    align: 'center',
  },
  {
    title: '等级状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
export default Vue.extend({
  data() {
    return {
      tableLoading: false,
      formState: {
        status: '1',
        gradeName: '',
        extend1: ''
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      dataSource: [],
      columns,
      visible: false,
      stVisible: false,
      deleteVisible: false,
      enVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      applyTime: [],
      loading: false,
      form: {
        gradeName: '',
        gradeCondition: '',
        rightsNbr: '',
        description: '',
      },
      rules: {
        gradeName: [
          {
            required: true,
            message: '请输入等级名称',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 50,
            message: '等级名称应不超过50个字符',
            trigger: 'blur',
          },
        ],
        gradeCondition: [
        {
            required: true,
            message: '请选择等级条件',
            trigger: 'change',
          },
        ],
        description: [
          {
            min: 0,
            max: 200,
            message: '等级说明不超过200个字符',
            trigger: 'blur',
          },
        ],
      },
      dataModalShow: false,
      disabled: false,
      checked1: false,
      checkList: [],
      gradeOptions: [],
      gradeText: '',
      conditionOps: {},
      conditionOpsValue: {},
      tempId:'',
      modaltt:''
    };
  },
  computed: {},
  components: { dataModal },
  mounted() {
    this.getMemberList();
  },
  methods: {
    moment,
    openData(){
      this.dataModalShow = true;
      this.$nextTick(() => {
        (this.$refs.dataModalRef as any).init();
      });
    },
    getMemberList() {
      this.tableLoading = true;
      const param = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        queryStatus: this.formState.recStatus,
        gradeName: this.formState.gradeName,
        status: this.formState.status,
        extend1: this.formState.extend1,
        startTime: this.applyTime[0],
        endTime: this.applyTime[1],
      };
      Api.getMembersByPage(param)
        .then((res) => {
          if (res.status === 200) {
            this.dataSource = res.result.list;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getMemberList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.pagination.current = 1;
      this.applyTime = [];
      this.getMemberList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getMemberList();
    },
    // 删除
    delHd(id) {
      this.tempId = id
      this.deleteVisible = true;
    },
    deleteSure() {
      Api.gradeDelete(this.tempId).then((res) => {
        const data = res;
        if (data.status === 200) {
          this.$message.success('删除成功');
          this.deleteVisible = false;
          this.handleSearchSubmit();
        } else {
          this.$message.error(data.message);
        }
      });
    },
    // 启用
    enableHd(id) {
      this.tempId = id
      this.enVisible = true;
    },
    enableSure() {
      Api.gradeEnable(this.tempId).then((res) => {
        if (res.status === 200) {
          this.$message.success('启用成功');
          this.enVisible = false;
          this.handleSearchSubmit();
          this.tempId = ''
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 停用
    stopHd(id,v1,v2) {
      if(v1>0 || v2>0){
        this.$message.error('该等级下有会员，无法停用')
      }else{
        this.tempId = id;
        this.stVisible = true;
      }
    },
    stopSure() {
      Api.gradeStop(this.tempId).then((res) => {
        const data = res;
        if (data.status === 200) {
          this.$message.success('停用成功');
          this.stVisible = false;
          this.handleSearchSubmit();
          this.tempId = ''
        } else {
          this.$message.error(data.message);
        }
      });
    },
    // 新增
    showModal() {
      this.form.gradeName = '';
      this.form.gradeCondition = '';
      this.form.gradeEquity = '';
      this.form.description = '';
      this.modaltt = '新增会员等级'
      this.visible = true;
      this.disable = false
      this.getRights()
      this.getCondition()
    },
    // 回显
    getQueryInfo(id){
      this.getRights()
      this.getCondition()
      this.visible = true;
      this.disabled = true;
      this.modaltt = '编辑会员等级'
      Api.gradeQuery(id)
        .then((res) => {
          if (res.status === 200) {
            this.form = res.result;
            this.checkList = res.result.gradeConditionDTOList.map((temp) => {
              this.checked1 = temp.conditionNbr
              let newOps = this.conditionOps[temp.conditionName].find(item=>item.sequenceNbr == temp.conditionNbr)
              newOps.timeValue = temp.timeValue
              newOps.timeUnit = temp.timeUnit
              newOps.statisticsValue = temp.statisticsValue
              newOps.statisticsUnit = temp.statisticsUnit

              if(temp.timeSpans == 'all'){
                let txt = ' 累计有效反馈 '+temp.statisticsValue+(temp.statisticsUnit=='0'?'元':(temp.statisticsUnit=='1'?'套':'项'))
                return {...temp,id:temp.conditionNbr,label:txt,title:temp.conditionName}
              }else if(temp.timeSpans == 'between'){
                let txt = ' 近 '+temp.timeValue+(temp.timeUnit=='0'?'年':(temp.timeUnit=='1'?'月':'日'))+' 累计有效反馈 '+temp.statisticsValue+(temp.statisticsUnit=='0'?'元':(temp.statisticsUnit=='1'?'套':'项'))
                return {...temp,id:temp.conditionNbr,label:txt,title:temp.conditionName}
              }
            })
          }
        })
    },
    // 提交
    handleOk() {
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          this.loading = true;
          if(this.disabled){
            this.updateApiHd()
          }else{
            this.addApiHd()
          }
        } else {
          return false;
        }
      });
    },
    addApiHd(){
      this.form.gradeConditionDTOList = this.checkList.map((item) => {
        return {
          conditionNbr: item.sequenceNbr,
          conditionType: item.conditionType,
          timeSpans: item.timeSpans,
          statisticsType: item.statisticsType,
          timeValue: item.timeValue,
          timeUnit: item.timeUnit,
          statisticsValue: item.statisticsValue,
          statisticsUnit: item.statisticsUnit,
        }
      })
      Api.addGrade(this.form)
        .then((res) => {
          if (res.status === 200) {
            this.$message.success('会员等级新增成功！');
            this.loading = false;
            this.visible = false;
            this.handleSearchSubmit();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateApiHd(){
      Api.updateGrade(this.form)
        .then((res) => {
          if (res.status === 200) {
            this.$message.success('会员等级编辑成功！');
            this.loading = false;
            this.visible = false;
            this.handleSearchSubmit();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm() {
      (this.$refs.ruleForm as HTMLFormElement).resetFields();
      this.visible = false;
    },
    // 取消
    handleCancel() {
      this.visible = false;
    },
    changeRightsNbr(){
      let temp = this.gradeOptions.filter(item=>item.sequenceNbr == this.form.rightsNbr)
      let arr = temp[0].rightsDiscountItemDTOList.map(item=>item.discountItemName)
      this.gradeText = arr.join('、')
    },
    getRights(){
      Api.getOptionRights()
        .then((res) => {
          if (res.status === 200) {
            this.gradeOptions = res.result;
          }
        })
    },
    getCondition(){
      Api.getConditionList()
        .then((res) => {
          if (res.status === 200) {
            this.conditionOps = res.result;
          }
        })
    },
    changeChecked1(key) {
      let temp = this.conditionOps[key].filter(item=>item.sequenceNbr == this.checked1)[0]
      if(temp.timeSpans == 'all'){
        let txt = ' 累计有效反馈 '+temp.statisticsValue+(temp.statisticsUnit=='0'?'元':(temp.statisticsUnit=='1'?'套':'项'))
        this.checkList = [{...temp,id:temp.sequenceNbr,label:txt,title:key}]
      }else if(temp.timeSpans == 'between'){
        let txt = ' 近 '+temp.timeValue+(temp.timeUnit=='0'?'年':(temp.timeUnit=='1'?'月':'日'))+' 累计有效反馈 '+temp.statisticsValue+(temp.statisticsUnit=='0'?'元':(temp.statisticsUnit=='1'?'套':'项'))
        this.checkList = [{...temp,id:temp.sequenceNbr,label:txt,title:key}]
      }
    },
    removeCheckList(line){
      this.checkList.forEach(item=>{
        if(item.id==line.id){
          this.checkList.splice(this.checkList.indexOf(item),1)
        }
      })
      if(line.title == '用户反馈'){
        this.checked1 = ''
      }
    },
    jumpRights(){
      window.open('/equityCenter')
    }
  },
});
