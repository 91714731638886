import http from '@/utils/http';

//  列表查询
export function getMembersByPage(formdata: any): any {
  return http.get(`/portal-resources/1/portalOperateGrade/queryByPage`, formdata);
}

//  权益下拉
export function getOptionRights(): any {
  return http.get(`/portal-resources/1/portalOperateRights/list`);
}

//  条件查询
export function getConditionList(): any {
  return http.get(`/portal-resources/1/portalOperateGrade/conditionList`);
}

// 新增
export function addGrade(formdata: any): any {
  return http.post(`/portal-resources/1/portalOperateGrade/add`, formdata);
}


// 编辑
export function updateGrade(formdata: any): any {
  return http.post(`/portal-resources/1/portalOperateGrade/update`, formdata);
}

// 启用
export function gradeEnable(id: any): any {
  return http.get(`/portal-resources/1/portalOperateGrade/enable/${id}`);
}

// 停用
export function gradeStop(id: any): any {
  return http.get(`/portal-resources/1/portalOperateGrade/stop/${id}`);
}

// 删除
export function gradeDelete(id: any): any {
  return http.get(`/portal-resources/1/portalOperateGrade/deleteById/${id}`);
}

// 回显
export function gradeQuery(id: any): any {
  return http.get(`/portal-resources/1/portalOperateGrade/queryById/${id}`);
}